import * as introJs from '../../node_modules/intro.js/intro';

(function ($) {
    /**
     * Hooks into all a.ajax links and form.ajax forms, intercepts them, and adds
     *
     * @param options
     */
    $.fn.ajaxUpdater = function (options) {
        var settings = $.extend({
            // called each time an ajax update was performed
            onComplete: function() {}
        }, options);

        // for all elemnts retrieved by $(this)... perform ajax updater operability
        $(this).each(function (i, e) {
            $(e)
                .on("click", "a.ajax", function() {
                    $(e).load($(this).attr("href"), function () {
                        settings.onComplete();
                    });
                    return false;
                })
                .on("submit", "form.ajax", function() {
                    $.ajax({
                        url: $(this).attr("action"),
                        type: $(this).attr("method"),
                        data: new FormData($(this)[0]),
                        success: function(data) {
                            $(e).html(data);
                            settings.onComplete();
                        },
                        cache: false,
                        contentType: false,
                        processData: false
                    });
                    return false;
                });
        });
    };


    $.fn.dynamicFormByPrototype = function(options) {
        var self = $(this);
        var settings = $.extend({
            prototypeName: "__name__",
            buttonAdd: null,
            counterNode: null,
            onAdd: function(form) {},
            onRemove: function() {}
        }, options);


        var parentLevel = self.parent().closest(".c0-form-prototype").data("c0-form-prototype") || 0;
        self.data("c0-form-prototype", parentLevel + 1);
        self.addClass("c0-form-prototype-l"+(parentLevel + 1));
        if (parentLevel > 0) {
            settings.prototypeName = "__name"+parentLevel+"__";
        }

        var $lengthElement = self.find(".c0-form-prototype-length:first");
        var collectionHolder = self.find("[data-prototype]:first");

        var elementCount = function () {
            return collectionHolder.find('> *').length;
        };

        collectionHolder.data('index', elementCount());

        var isTable = collectionHolder[0].tagName.toLowerCase() === "tbody";

        function addTagForm(collectionHolder) {
            // Get the data-prototype explained earlier
            var prototype = collectionHolder.data('prototype');
            // get the new index
            var index = collectionHolder.data('index');
            // Replace '__name__' in the prototype's HTML to
            // instead be a number based on how many items we have
            var regExp = new RegExp(settings.prototypeName, "g");
            var newForm = prototype.replace(regExp, index);
            // increase the index with one for the next item
            collectionHolder.data('index', index + 1);
            // Display the form in the page in an li

            collectionHolder.append(newForm);

            onDomUpdate();

            return collectionHolder.find("> *:last");
        }

        var onDomUpdate = function() {
            var cnt = elementCount();
            if ($lengthElement.length > 0) {
                $lengthElement.val(cnt);
            }

            self.find(".c0-form-prototype-info-empty").toggle(cnt === 0);

            // enumerate
            collectionHolder
                .find(".c0-form-prototype-enumerator")
                .not(".c0-form-prototype-l"+(parentLevel+2)+" [data-prototype] .c0-form-prototype-enumerator")
                .each(function (i) {
                    $(this).text(i + 1);
                });

            if (!isTable) {
                return false;
            }
            collectionHolder.closest("table").toggle(cnt > 0);
        };

        onDomUpdate();


        self.on("click", ".c0-form-prototype-add", function(e) {
            // match no elements from subclass
            if ($(this).is(".c0-form-prototype-l"+(parentLevel+2)+" .c0-form-prototype-add")) {
                return;
            }
            // add a new tag form (see next code block)
            var $form = addTagForm(collectionHolder);

            self.trigger("element_add");
            self.trigger("element_change", [ elementCount() ]);

            settings.onAdd($form);

            e.preventDefault();
        });


        // add remove handler to element
        self.on("click", ".c0-form-prototype-remove", function (e) {
            if ($(this).is(".c0-form-prototype-l"+(parentLevel+2))) {
                return;
            }
            $(this).closest("tr, li").remove();
            onDomUpdate();

            self.trigger("element_remove");
            self.trigger("element_change", [ elementCount() ]);
            settings.onRemove();

            e.preventDefault();
        });

        self.on("set_count", function (e, count) {
            e.stopPropagation();

            collectionHolder.html("");
            collectionHolder.data("index", 0);
            for (var i = 1; i <= count; i++) {
                var $form = addTagForm(collectionHolder);
                settings.onAdd($form);
            }
            onDomUpdate();
        });

        self.trigger("prototype_initialized", [ elementCount() ]);
    };
})(jQuery);

/**
 * Listens on ".c0-form-prototype" and ".c0-form-prototype-add" and "[data-prototype]" of collection
 *
 * Fetches the buttons first, looks up in dom tree for corresponding context-container
 *
 * @param $context
 */
function parseFormPrototype($context) {
    $context
        .find(".c0-form-prototype")
        .each(function (i, e) {
            $(e).dynamicFormByPrototype({
                onAdd: function($element) {
                    parseFormPrototype($element);
                }
            });
        });
}

function storeSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function storeGet(key) {
    return JSON.parse(localStorage.getItem(key));
}

function showOnboarding() {
    const intro = introJs();
    intro.setOption('exitOnOverlayClick', false);
    intro.start();
}

function initOnboarding() {
    var onboardedPages = storeGet('onboardedPages'),
        route = jQuery('body').attr('data-route');

    if(onboardedPages === null) {
        onboardedPages = {};
    }

    if(typeof onboardedPages[route] === 'undefined' && jQuery('[data-intro]').length) {
        showOnboarding();
        onboardedPages[route] = true;
        storeSet('onboardedPages', onboardedPages);
    }


    // intro.onbeforechange(function(targetElement) {
    //     console.log('onbeforechange', targetElement);
    // });
}

$(function () {
    parseFormPrototype($("body"));
    initOnboarding();
});

$('select:not(.ignore-select2)')
    .each(function (i, select) {
        var $select = $(select);
        // set width to 100% if there is no width parameter set
        if (!$select[0].style.width.length > 0) {
            $select.css('width', '100%');
        }

        var hasEmpty = '' === $select.find('option:first').val();

        $select.select2({
            theme: 'bootstrap',
            placeholder: {
                id: ''
            },
            allowClear: hasEmpty,
            minimumResultsForSearch: 10
        });
    });